import axios from 'axios';


class webApi {
    constructor(host) {
        this.host = host
        let api = webApi.apiList();
        this.path = 'Game';
        this.pichost = 'https://vimg.lolicandyhouse.top/getpix'
        this.pichostUpload = 'https://vimg.lolicandyhouse.top/uploadpix'
        axios.defaults.withCredentials = true;
        for (let key in api) {
            Object.defineProperty(this, key, {
                get: function () {
                    this.path = 'Game.' + key
                    return this
                }
            })
            for (let key2 in api[key]) {
                Object.defineProperty(this, api[key][key2], {
                    get: function () {
                        this.path += '.' + api[key][key2]
                        return this
                    }
                })
            }
        }

    }

    async get(param = null) {
        let params = {
            s: this.path,
            ...param
        }
        let res = await axios.get(this.host, {
            params
        });
        return res
    }

    async post(data = null) {
        let params = {
            s: this.path
        }
        let res = null
        if (data == null) {
            res = await axios.post(this.host, null, {
                params
            });
        } else {
            res = await axios.post(this.host, data, {
                params
            });
        }

        return res
    }



    static apiList() {
        return {
            base: [
                'addgame',
                'updategame',
                'getgamelist',
                'getgameinfo',
                'updategametag',
                'updategamepic',
                'updategamefile',
                'updategameothername',
                'removegame'
            ],
            tag: [
                'gettaglist',
                'addtag',
                'updatetag',
                'gettaginfo',
                'removetag'
            ],
            file: [
                'getfilegame'
            ],
            user: [
                'login',
                'checksession',
                'register',
                'activateuser',
                'profile'
            ],
            web: [
                'getmenulist'
            ],
            pic: [
                'addpic',
                'removepic',
                'getpicinfo'
            ]
        }
    }

    static getAxios() {
        return axios;
    }

    getDefaultPicLink(picinfo) {
        let link = ''

        link = this.pichost + '/' + picinfo.md5 + '.' + picinfo.ext

        return link
    }

    getDefaultPicUploadLink() {
        return this.pichostUpload
    }



    async uploadPic(bs64Pic) {
        let data = {
            body: bs64Pic,
            user_id: this.getCookie('user_id'),
            token: this.getCookie('token'),
            pwd: 'q1w2e3r4t5y6u7i8o9p0'
        }

        let res = await axios({
            method: 'PUT',
            url: this.getDefaultPicUploadLink(),
            data,
            withCredentials: false
        })

        return res

    }

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
    }

}

export default new webApi('https://study.lolicandyhouse.top/phalapi/public/');
// export default new webApi('http://localhost');