import VueRouter from "vue-router";
import api from '../api';



const router = new VueRouter({
    routes: [{
            path: '/',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('../components/LoginPage.vue')
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('../components/RegisterPage.vue')
        },
        {
            path: '/activative',
            name: 'activative',
            component: () => import('../components/ActivatePage.vue')
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('../components/HomePage.vue'),
            children: [{
                path: '/game',
                name: 'Game',
                component: () => import('../components/game/GameView.vue')
            }, {
                path: '/home',
                name: 'Home',
                redirect: '/game'
                // component: () => import('../components/home/HomeView.vue')
            }, {
                path: '/user',
                name: 'User',
                component: () => import('../components/user/UserView.vue')
            }, {
                path: '/file',
                name: 'File',
                component: () => import('../components/file/FileView.vue')
            }, {
                path: '/tag',
                name: 'Tag',
                component: () => import('../components/tag/TagView.vue')
            }, {
                path: '/invcode',
                name: 'Invcode',
                component: () => import('../components/invcode/InvcodeView.vue')
            }]
        }
    ]
})

window.isCheckSession = false;
router.beforeEach(
    async (to, from, next) => {
        if (window.isCheckSession || to.path == '/login' || to.path == '/register' || to.path == '/activative') {
            next()
        } else {
            let $res = await api.user.checksession.get()
            if ($res['data']['data']['is_login']) {
                window.isCheckSession = true;
                next()
            } else {
                next('/login')
            }
        }
    }
)

export default router;