import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import router from './router';

import VueCookies from 'vue-cookies';
import webApi from './api';

Vue.prototype.$api=webApi;

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueCookies);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,

}).$mount('#app')
